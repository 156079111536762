<template>
  <v-app>
    <NavBar />
    <v-main>
      <Header>
        <!-- calculadora -->
          <v-card
            id="calc"
            class="pa-0 rounded-0 text-center"
            v-bind:class="[
              $vuetify.breakpoint.smAndDown ? '' : 'mt-7 ml-7'
            ]"
            :height="$vuetify.breakpoint.smAndDown ? '100%' : 500"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : 500"
            >
                <v-card flat class="text-center px-5" v-if="step===0">
                    <v-col class="my-2 text-center">
                      <v-avatar
                        size="80"
                        tile
                      >
                        <v-img :src="`${publicPath}img/icbc-logo.png`" width="80" height="100" contain></v-img>
                      </v-avatar>
                    </v-col>
                    <h4 class="subtitle-1 text-uppercase">
                      Simulá tu préstamo y financiá
                      tu próximo vehículo en cuotas
                      fijas y en pesos
                    </h4>
                    <v-form v-model="valid">
                      <v-container fluid>
                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="amount"
                              :rules="amountRules"
                              label="Valor del vehículo"
                              v-money="money"
                              persistent-hint
                              required
                              prefix="$"
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-select
                              v-model="year"
                              :items="years"
                              :rules="yearRules"
                              label="Año del vehículo"
                              required
                              dense
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="loanAmount"
                              :rules="loanAmountRules"
                              label="¿Cuánto necesitás financiar?"
                              v-money="money"
                              persistent-hint
                              required
                              prefix="$"
                              @input="calcLoanAmount"
                              dense
                            ></v-text-field>
                            <p class="caption text-left" v-show="loanAmountError">El monto ingresado supera el {{loanMaxPercentage}}%</p>
                            <p class="caption text-left" v-show="loanMaxPercentage">Podés financiar hasta un {{loanMaxPercentage}}% del valor del auto</p>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                          >
                              <v-col md="12">
                                <p>¿Cuántas Cuotas?</p>
                              </v-col>
                              <v-col sm="12">
                                <v-item-group
                                  v-model="loanSelectedItem"
                                  @change="selectMonths()"
                                  class="text-center"
                                  mandatory>
                                   <v-item v-slot="{ active, toggle }"
                                    v-for="(item) in loanMonths"
                                    :key="item"
                                  >
                                    <v-btn
                                      small
                                      :color="active ? 'primary' : ''"
                                      :value="item"
                                      @click="toggle"
                                      :disabled="!validAmount"
                                      class="ml-2"
                                    >
                                    {{item}}
                                    </v-btn>
                                  </v-item>
                                </v-item-group>
                              </v-col>
                          </v-col>
                          <v-col class="my-2 text-center">
                            <v-btn
                              width="100%"
                              height="50px"
                              color="primary"
                              @click="next(1)"
                              :disabled="!valid || !validAmount || !loanSelectedMonths"
                            >
                              SIMULAR PRÉSTAMO
                            </v-btn>
                            <p class="caption font-weight-thin">Powered by Simplicar</p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                </v-card>
                <v-card flat class="text-center px-5" v-if="step===1">
                    <v-col class="my-2 text-center">
                      <a href="#"
                         class="float-left mt-7"
                         style="
                          font: normal normal normal 13px/26px Source Sans Pro;
                          letter-spacing: 0px;
                          color: #6C6C6C;
                          opacity: 1;
                          opacity: 1;"
                          @click="back(0)"
                      >
                        Volver
                      </a>
                      <v-avatar
                        size="80"
                        tile
                      >
                        <v-img :src="`${publicPath}img/icbc-logo.png`" width="80" height="100" contain></v-img>
                      </v-avatar>
                    </v-col>
                    <v-row>
                      <v-col cols="4" class="pa-2">
                        <p class="text-caption">Valor de Cuota Promedio:</p>
                        <p class="">{{cuotaPromedio | currency('$', 0, { thousandsSeparator: '.' })}} <br>/mes</p>
                      </v-col>
                      <v-col cols="4" class="pa-2">
                        <p class="text-caption">Valor Primera Cuota:</p>
                        <p>{{cuotas.length ? cuotas[0].ctaTotal :'' | currency('$', 0, { thousandsSeparator: '.' })}}</p>
                      </v-col>
                      <v-col cols="4" class="pa-2">
                        <p class="text-caption">Valor Última Cuota:</p>
                        <p>{{cuotas.length ? cuotas[cuotas.length-1].ctaTotal:'' | currency('$', 0, { thousandsSeparator: '.' })}}</p>
                      </v-col>
                      <v-col cols="4" class="pa-2">
                        <p class="text-caption">Desembolso a cargo del tomador del préstamo: {{ loanTotalAmount | currency('$', 0, { thousandsSeparator: '.' }) }}</p>
                      </v-col>
                      <v-col cols="4" class="pa-2">
                        <p class="text-caption">T.N.A: {{tna}}%</p>
                      </v-col>
                      <v-col cols="4" class="pa-2">
                        <p class="text-caption">CFTNA: {{cftna}}%</p>
                      </v-col>
                    </v-row>
                    <p class="caption font-weight-thin">(*)El CFT y la cuota incluye Capital, Intereses, IVA sobre intereses. No incluyen Seguro del bien.</p>
                    <v-dialog
                      v-model="cuadroMarcha"
                      persistent
                      max-width="100%"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          Ver Cuadro De Marcha
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Cuadro de Marcha</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container>
                            <v-simple-table
                              fixed-header
                            >
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      Cuota
                                    </th>
                                    <th class="text-left">
                                      Saldo
                                    </th>
                                    <th class="text-left">
                                      Cta. Interés
                                    </th>
                                    <th class="text-left">
                                      Cta. Capital
                                    </th>
                                    <th class="text-left">
                                      I.V.A
                                    </th>
                                    <th class="text-left">
                                      Cta. Pura
                                    </th>
                                    <th class="text-left">
                                      Cta. Total
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="item in cuotas"
                                    :key="item.cuota"
                                  >
                                    <td>{{ item.cuota}}</td>
                                    <td>{{ item.saldo | currency('$', 0, { thousandsSeparator: '.' }) }}</td>
                                    <td>{{ item.ctaInteres | currency('$', 0, { thousandsSeparator: '.' }) }}</td>
                                    <td>{{ item.ctaCapital | currency('$', 0, { thousandsSeparator: '.' }) }}</td>
                                    <td>{{ item.iva | currency('$', 0, { thousandsSeparator: '.' }) }}</td>
                                    <td>{{ item.ctaPura | currency('$', 0, { thousandsSeparator: '.' }) }}</td>
                                    <td>{{ item.ctaTotal | currency('$', 0, { thousandsSeparator: '.' }) }}</td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="cuadroMarcha = false"
                          >
                            Cerrar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-col class="my-2 text-center">
                      <v-btn
                        width="100%"
                        height="50px"
                        color="primary"
                        @click="next(2)"
                      >
                        SIGUENTE
                      </v-btn>
                    </v-col>
                    <p class="caption font-weight-thin">Powered by Simplicar</p>

                    <p class="caption font-weight-thin">(**)valores en efectivo apróximados; incluye gastos de patentamiento o transferencia, gastos gestoria e inscripción de prenda sujeto a modificaciones en concesionario y registro. En el caso de la Provincia de Mendoza se adicionará gasto de sellado sujeto a ley 9212.</p>
                </v-card>
                <v-card flat class="text-center px-5" v-if="step===2">
                    <v-col class="my-2 text-center">
                      <a href="#"
                         class="float-left mt-7"
                         style="
                          font: normal normal normal 13px/26px Source Sans Pro;
                          letter-spacing: 0px;
                          color: #6C6C6C;
                          opacity: 1;
                          opacity: 1;"
                          @click="back(1)"
                      >
                        Volver
                      </a>
                      <v-avatar
                        size="80"
                        tile
                      >
                        <v-img :src="`${publicPath}img/icbc-logo.png`" width="80" height="100" contain></v-img>
                      </v-avatar>
                    </v-col>
                    <div v-if="success">
                      <h4 class="display-1 text-uppercase my-10">
                        ¡Gracias por su consulta!
                      </h4>
                      <p class="body-1 text-uppercase">
                        Dentro de las próximas 24hs hábiles, te contactaremos
                        para asesorarte
                      </p>
                    </div>
                    <div v-else>
                      <h4 class="subtitle-1 text-uppercase">
                        Ingresá tus datos y te llamamos para asesorarte en la obtención
                        del préstamo
                      </h4>
                      <v-form v-model="valid2">
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="firstname"
                                :rules="nameRules"
                                label="Nombre"
                                required
                                dense
                              ></v-text-field>
                            </v-col>

                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="lastname"
                                :rules="nameRules"
                                label="Apellido"
                                required
                                dense
                              ></v-text-field>
                            </v-col>

                            <v-col
                              cols="12"
                              md="2"
                            >
                              <v-text-field
                                v-model="pref"
                                :rules="prefRules"
                                label="Pref"
                                required
                                dense
                              ></v-text-field>
                            </v-col>

                            <v-col
                              cols="12"
                              md="9"
                            >
                              <v-text-field
                                v-model="phone"
                                :rules="phoneRules"
                                label="Celular"
                                required
                                dense
                              ></v-text-field>
                            </v-col>

                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="email"
                                :rules="emailRules"
                                label="E-mail"
                                required
                                dense
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-radio-group
                                v-model="iAm"
                                :rules="iAmRules"
                                row
                                dense
                                class="mt-0"
                              >
                                <v-radio
                                  label="Soy Empresa"
                                  value="empresa"
                                ></v-radio>
                                <v-radio
                                  label="Soy Particular"
                                  value="particular"
                                ></v-radio>
                            </v-radio-group>
                            </v-col>

                            <v-col class="my-2 text-center">
                              <v-btn
                                width="100%"
                                height="50px"
                                color="primary"
                                @click="submit"
                                :disabled="!valid2"
                              >
                                SOLICITAR PRÉSTAMO
                              </v-btn>
                              <p class="caption font-weight-thin">Powered by Simplicar</p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </div>
                </v-card>
          </v-card>
      </Header>
      <Benefits />
      <Faqs :items="config && config.faqs ? config.faqs : []"/>
      <Contact />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>

import {mask} from 'vue-the-mask'

import NavBar from './components/NavBar';
import Header from './components/Header';
import Footer from './components/Footer';
import Benefits from './components/Benefits';
import Faqs from './components/Faqs';
import Contact from './components/Contact';

export default {
  name: 'App',
  components: {
    NavBar,
    Header,
    Footer,
    Benefits,
    Faqs,
    Contact
  },
  directives: {
    mask
  },
  computed : {
    publicPath () {
      return this.$store.state.publicPath;
    },
    years () {
      const years = [];
      let startYear = this.currentYear - this.allowedCarYear;
      console.log(startYear)
      while ( startYear <= this.currentYear ) {
          years.push(startYear++);
      }
      return years.sort((a, b) => b - a);
    },
    loanMaxPercentage () {
      const yearDiff = window.parseInt(this.currentYear) - window.parseInt(this.year);
      let percentage = 0;
      if (yearDiff <= 1 ) {
        percentage = 70;
      } else if (yearDiff > 1 && yearDiff <= 5) {
        percentage = 65;
      } else if (yearDiff > 5) {
        percentage = 60;
      }
      return percentage;
    },
    loanMonths () {
      let mounths = [12,24,36,48,60];
      const yearDiff = window.parseInt(this.currentYear) - window.parseInt(this.year);
      if (yearDiff >= 2 && yearDiff <= 4) {
        mounths = [12,24,36,48];
      } else if (yearDiff > 4) {
        mounths = [12,24,36];
      }
      return mounths;
    },
    loanTotalAmount() {
      const amount = window.parseInt(this.amount.replaceAll('.', ''));
      const loanAmount = window.parseInt(this.loanAmount.replaceAll('.', ''));
      const value = ((amount - loanAmount) * this.fees.percentaceVariant) + (amount - loanAmount) + (loanAmount * this.fees.percentageToFinance) + (amount * this.fees.percentageUnit) + this.fees.commercialManagement;
      console.log(value);
      return value;
    },
    tna () {
      const months = this.loanSelectedMonths;
      let tna = 80;
      this.rates.map((el)=>{
        if(el.months === months){
          tna = el.rate;
        }
      });
      return tna;
    },
    tem () {
      const value = this.tna * 30 / 360;
      return value;
    },
    temVariable () {
      const val = Math.round((this.tem / 100) * 20) / 20;
      return val;
    },
    tea () {
      return (1+(this.tem/100))^(360/30)-1;
    },
    cuotaPura () {
      /* eslint-disable */
      const loanAmount = window.parseInt(this.loanAmount.replaceAll('.', ''));
      const months = this.loanSelectedMonths;
      const a = 1 + ( this.tna / 100 / 12 );
      const b = this.tna / 100 / 12;
      const val2 = Math.pow(a, months) * b;

      const c = 1 + (this.tna / 100 / 12);
      const val3 = Math.pow(c, months) - 1;
      const total = loanAmount * (val2 / val3);
      return Math.round(total);
    },
    cuotas () {
      const values = [];
      const loanAmount = window.parseInt(this.loanAmount.replaceAll('.', ''));
      for (let i = 0; i < this.loanSelectedMonths; i++) {
        const saldo = i === 0 ? loanAmount : (values[i-1].saldo - values[i-1].ctaCapital);
        const ctaInteres = Math.round((saldo * this.tem) / 100);
        const ctaCapital = Math.round(this.cuotaPura - ctaInteres);
        const iva = Math.round(ctaInteres * 0.21);
        const ctaPura = Math.round(ctaCapital + ctaInteres);
        const ctaTotal = Math.round(ctaInteres + ctaCapital + iva);
        const val = {
          cuota: i+1,
          saldo: saldo,
          ctaInteres: ctaInteres,
          ctaCapital: ctaCapital,
          iva: iva,
          ctaPura: ctaPura,
          ctaTotal: ctaTotal,
        };
        values.push(val);
      }
      return values;
    },
    cuotaPromedio () {
      let value = 0;
      if (this.cuotas.length) {
        this.cuotas.map((el) => value+= el.ctaTotal);
        return Math.ceil(value / this.cuotas.length);
      }
      return value;
    },
    cftTea () {
      let value = 0;
      const values = [];
      if (this.cuotas.length) {
        this.cuotas.map((el) => values.push(el.ctaTotal));
        value = Math.pow( (1 + 0.054), (360/30) ) - 1;
        return Math.round(value * 100);
      }
      return value;
    },
    cftna () {
      /* eslint-disable */
      const loanAmount = window.parseInt(this.loanAmount.replaceAll('.', ''));
      const values = [];
      values.push(loanAmount);
      this.cuotas.map(el => values.push(el.ctaTotal*(-1)));
      const irrResult = this.IRR(values);
      const total = irrResult * 12 * 100
      return total.toFixed(2);
    },
    fees () {
      return this.config.fees;
    },
  },
  data: () => ({
    allowedCarYear: 8,
    rates: [],
    config: null,
    cuadroMarcha: null,
    success: false,
    step: 0,
    valid: false,
    validAmount: false,
    currentYear: new Date().getFullYear(),
    amount: 0, // vehicle amount
    money: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 0,
      masked: false
    },
    amountRules: [
      v => !!v || 'requerido',
    ],
    year: '',
    yearRules: [
      v => !!v || 'requerido',
    ],
    loanAmount: 0,
    loanSelectedItem: 0,
    loanSelectedMonths: 12,
    loanAmountError: false,
    loanAmountRules: [
      v => !!v || 'requerido',
    ],
    valid2: false,
    firstname: '',
    lastname: '',
    nameRules: [
      v => !!v || 'requerido',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail es requerido',
      v => /.+@.+/.test(v) || 'E-mail debe ser válido',
    ],
    pref: '',
    prefRules: [
      v => !!v || 'requerido',
    ],
    phone: '',
    phoneRules: [
      v => !!v || 'requerido',
    ],
    iAm: '',
    iAmRules: [
      v => !!v || 'requerido',
    ]
  }),
  created() {
    fetch('https://simplicar.com/wp-json/wp/v2/prendario/1151')
    .then(response => response.json())
    .then(dataJson => {
      this.config = JSON.parse(dataJson.config)
      this.rates = this.config.rates;
      this.allowedCarYear = this.config.allowedCarYear;
    })
    .catch(err => console.log('Solicitud fallida', err));
  },
  mounted () {
    this.$vuetify.theme = this.$store.state.theme;
    const hubspot = document.createElement('script')
    hubspot.setAttribute('src', 'https://js.hs-scripts.com/7332789.js')
    const favicon = document.createElement('link')
    favicon.setAttribute('rel', 'icon')
    favicon.setAttribute('href', this.config.site_config.favicon)
    document.head.appendChild(favicon)
  },
  methods: {
    next (val) {
      this.step = val;
    },
    back (val) {
      this.step = val;
    },
    calcLoanAmount () {
      const amount = window.parseInt(this.amount.replaceAll('.', ''));
      const loanAmount = window.parseInt(this.loanAmount.replaceAll('.', ''));
      const loanPercentage = (loanAmount * 100) / amount;
      if (loanPercentage <= this.loanMaxPercentage) {
        this.validAmount = true;
        this.loanAmountError = false;
      } else if (loanPercentage > this.loanMaxPercentage) {
        this.validAmount = false;
        this.loanAmountError = true;
      }
    },
    selectMonths () {
      /* eslint-disable */
      this.loanSelectedMonths = this.loanMonths[this.loanSelectedItem];
    },
    submit () {
      const xhr = new XMLHttpRequest();
      const url = 'https://api.hsforms.com/submissions/v3/integration/submit/7332789/c70aee4a-0af9-4b12-baf9-1984595d74d1';
      const loanAmount = window.parseInt(this.loanAmount.replaceAll('.', ''));
      const self = this;
      const thousandsSeparator = { thousandsSeparator: '.' };
      const messageTpl = `
        Simulación\n
        Valor de cuota promedio: ${this.$filters.currency(this.cuotaPromedio,'$',0,thousandsSeparator)}\n
        Valor primera cuota: ${this.$filters.currency(this.cuotas.length ? this.cuotas[0].ctaTotal : '','$',0,thousandsSeparator)}\n
        Valor Cuota final: ${this.$filters.currency(this.cuotas.length ? this.cuotas[this.cuotas.length-1].ctaTotal:'','$',0,thousandsSeparator)}\n
        Desembolso a cargo del tomador del préstamo: ${ this.$filters.currency(this.loanTotalAmount,'$',0,thousandsSeparator)}\n
        T.N.A: %${this.tna}\n
        CFTNA: %${this.cftna}\n
      `;

      const data = {
        "submittedAt": Date.now(),
        "fields": [
          {
            "name": "email",
            "value": this.email,
          },
          {
            "name": "valor_del_vehiculo",
            "value": this.amount,
          },
          {
            "name": "antiguedad_del_vehiculo",
            "value": this.year,
          },
          {
            "name": "cuanto_necesitas_financiar_",
            "value": this.$filters.currency(loanAmount,'$',0,thousandsSeparator),
          },
          {
            "name": "en_cuantas_cuotas_",
            "value": this.loanSelectedMonths,
          },
          {
            "name": "firstname",
            "value": this.firstname,
          },
          {
            "name": "lastname",
            "value": this.lastname,
          },
          {
            "name": "phone",
            "value": `(${this.pref})${this.phone}`
          },
          {
            "name": "jobtitle",
            "value": this.iAm
          },
          {
            "name": "message",
            "value": messageTpl
          },
          {
            "name": "source",
            "value": location.host
          }
        ],
        "context": {
          //"hutk": document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, "$1"),
          "pageUri": location.origin,
          "pageName": "Simulador"
        }
      }


      var final_data = JSON.stringify(data)

      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.onreadystatechange = function() {
          if(xhr.readyState == 4 && xhr.status == 200) {
              self.success = true // Returns a 200 response if the submission is successful.
          } else if (xhr.readyState == 4 && xhr.status == 400){
              console.error(xhr.responseText); // Returns a 400 error the submission is rejected.
          } else if (xhr.readyState == 4 && xhr.status == 403){
              console.error(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
          } else if (xhr.readyState == 4 && xhr.status == 404){
              console.error(xhr.responseText); //Returns a 404 error if the formGuid isn't found
          }
      }

      xhr.send(final_data)
    },
    IRR(values, guess) {
      // Credits: algorithm inspired by Apache OpenOffice

      // Calculates the resulting amount
      var irrResult = function(values, dates, rate) {
        var r = rate + 1;
        var result = values[0];
        for (var i = 1; i < values.length; i++) {
          result += values[i] / Math.pow(r, (dates[i] - dates[0]) / 365);
        }
        return result;
      }

      // Calculates the first derivation
      var irrResultDeriv = function(values, dates, rate) {
        var r = rate + 1;
        var result = 0;
        for (var i = 1; i < values.length; i++) {
          var frac = (dates[i] - dates[0]) / 365;
          result -= frac * values[i] / Math.pow(r, frac + 1);
        }
        return result;
      }

      // Initialize dates and check that values contains at least one positive value and one negative value
      var dates = [];
      var positive = false;
      var negative = false;
      for (var i = 0; i < values.length; i++) {
        dates[i] = (i === 0) ? 0 : dates[i - 1] + 365;
        if (values[i] > 0) positive = true;
        if (values[i] < 0) negative = true;
      }

      // Return error if values does not contain at least one positive value and one negative value
      if (!positive || !negative) return '#NUM!';

      // Initialize guess and resultRate
      var guess = (typeof guess === 'undefined') ? 0.1 : guess;
      var resultRate = guess;

      // Set maximum epsilon for end of iteration
      var epsMax = 1e-10;

      // Set maximum number of iterations
      var iterMax = 50;

      // Implement Newton's method
      var newRate, epsRate, resultValue;
      var iteration = 0;
      var contLoop = true;
      do {
        resultValue = irrResult(values, dates, resultRate);
        newRate = resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
        epsRate = Math.abs(newRate - resultRate);
        resultRate = newRate;
        contLoop = (epsRate > epsMax) && (Math.abs(resultValue) > epsMax);
      } while(contLoop && (++iteration < iterMax));

      if(contLoop) return '#NUM!';

      // Return internal rate of return
      return resultRate;
    }
  }
};
</script>